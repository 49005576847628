<template>
  <div>
    <vm-table ref="vmTable" :filter.sync="search" url="feedbacks" @get-table-data="getTableData">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <table-column type="expand" :width="60">
        <template #default="scope">
          <div style="display: flex;padding-left: 60px;">
            <div v-for="item in scope.row.files" :key="item.id">
              <el-image v-if="item.fileType === 1" style="width: 200px; height: 200px;margin-right: 10px;"
                :src="item.path" :preview-src-list="srcList"></el-image>
              <video :src="item.path" width="200" height="200" style="margin-right: 10px;" controls></video>
            </div>
          </div>
        </template>
      </table-column>
      <table-column prop="billNo" :width="120" align="center" label="编号"></table-column>
      <table-column prop="suggestion" label="问题或意见"></table-column>
      <table-column prop="mobile" :width="200" align="center" label="联系方式"></table-column>
      <table-column prop="createTime" :width="140" align="center" label="提交时间"></table-column>
    </vm-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        filter: "",
      },
      srcList: []
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    getList(pageNum) {
      this.srcList = [];
      this.$refs.vmTable.getList(pageNum);
    },

    getTableData(tableData) {
      tableData.forEach(item => {
        if (item.files.length > 0) {
          item.files.forEach(each => {
            if (each.fileType === 1) {
              this.srcList.push(each.path);
            }
          });
        }
      });
    }
  },
};
</script>